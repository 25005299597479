.socialBtnContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100% - 30px);
    height: 100vh;
    h4{
        margin-top: 0;
        margin-bottom: 0px;
    }
    h3{
        margin: 0;
    }
    .btnstyle {
        display: flex;
        align-items: center;
        padding: 0px;
        border-radius: 0px;
        background: radial-gradient(126.96% 126.96% at 6.47% 97.81%, #fa8f21 9%, #d82d7e 78%);
        color: #fff;
        font-size: 18px;
        line-height: 20px;
        height: auto;
        border: 0px solid transparent;
        span {
            padding: 10px 20px;
            padding-left: 0;
            &:global(.ant-btn-icon) {
                padding: 10px 25px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-right: 0px solid transparent;
                margin-right: 0px !important;
                img {
                    width: 48px;
                }
            }
            &:global(.anticon-loading) {
                padding: 0;
                svg {
                    width: 38px;
                    height: 38px;
                }
            }
        }
        &:hover {
            span {
                color: #fff;
            }
            border-color: transparent;
            color: #fff !important;
        }
    }
    .textBtn {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        max-width: 500px;
        width: 100%;
        margin: 0 auto;
        padding: 30px 30px;
        border-radius: 10px;
        box-shadow: 0px 0px 5px #00000040;
        ul {
            width: 100%; 
            margin-bottom: 30px;
        }
    }
}

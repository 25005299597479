:global(.ant-drawer-content) {
    &.contentDrawer {
        background-color: #1f2732;

        :global(.ant-drawer-header) {
            border-bottom: 1px solid #2e3133;
            padding: 8px 30px;

            :global(.ant-drawer-header-title) {
                :global(.ant-drawer-close) {
                    display: none;
                }

                :global(.ant-drawer-title) {
                    .topTitleDrawer {
                        display: flex;
                        justify-content: space-between;
                        width: 100%;
                        align-items: center;

                        h4 {
                            color: #fff;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 21px;
                            margin: 0;
                            display: flex;
                            align-items: center;

                            span:global(.anticon) {
                                color: #55585a;
                                margin: 0 8px;
                            }

                            span.activelink {
                                color: #ffffff;
                            }
                        }

                        .right {
                            display: flex;
                            align-items: center;
                            gap: 20px;

                            span:global(.anticon) {
                                color: #55585a;
                                padding: 4px;
                                width: 24px;
                                height: 24px;
                                display: flex;
                                align-items: center;

                                svg {
                                    width: 24px;
                                    height: 24px;
                                }
                            }
                        }
                    }
                }
            }
        }

        :global(.ant-drawer-body) {
            padding: 20px 30px;
            margin: 8px 8px 8px 0px;

            .drawerBodyContainer {
                height: 100%;
                display: flex;
                align-items: end;
                flex-direction: column;

                :global(.ant-input) {
                    border: 1px solid #2e3133;
                    background: #1F222A;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    color: #ffffff;
                    line-height: 20px;
                    padding: 20px;

                    &::placeholder{
                        color: #ffffff;
                    }
                }


                .replyStyle {
                    margin-top: 20px;
                }
            }
        }

        :global(.ant-drawer-footer) {
            padding: 10px 30px 30px 30px;
        }

    }
}
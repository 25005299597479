.tableSection {
  position: relative;
  :global(.ant-checkbox) {
    :global(.ant-checkbox-inner) {
      background-color: #1f222a;
      border-color: #5c6f87;
      width: 20px;
      height: 20px;
    }
  }
  .teamemberTable {
    :global(.ant-table-container) {
      width: 100%;
      overflow: auto;
      max-height: 560px;
      @media (max-width: 1000px) {
        white-space: nowrap;
      }
      @media (max-width: 991px) {
        max-height: 400px;
      }
    }
    :global(.ant-table) {
      background: #181a20;
      table {
        border-spacing: 0 5px;
        padding: 10px;
        table-layout: fixed;
        :global(.ant-table-thead) {
          :global(.ant-table-cell) {
            background: transparent;
            border: 0px solid #262a35;
            border-radius: 0px;
            font-weight: 600;
            font-size: 16px;
            color: #8d8d8d;
            white-space: nowrap;
            &:global(.ant-table-cell-row-hover) {
              background: #181a20;
            }
            &::before {
              background-color: transparent;
              width: 0px;
              height: 0px;
              display: none;
            }
          }
        }
        :global(.ant-table-tbody) {
          background: #181a20;
          :global(.ant-table-row) {
            :global(.ant-table-cell) {
              border: 1px solid #262a35;
              border-left-width: 0px;
              border-right-width: 0px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              color: white;
              margin-bottom: 10px;
              font-size: 14px;
              font-weight: 400;
              white-space: nowrap;
              a {
                color: white;
              }
              &:first-child {
                border-left-width: 1px;
                border-radius: 12px 0 0 12px;
              }
              &:last-child {
                margin-bottom: 5px;
                border-right-width: 1px;
                border-radius: 0 12px 12px 0;
              }
              &:global(.ant-table-cell-row-hover) {
                background: #181a20;
              }
              &::before {
                background-color: transparent;
                width: 0px;
                height: 0px;
                display: none;
              }
            }
          }
        }
      }
    }
    :global(.ant-pagination) {
      background-color: transparent;
      padding: 16px;
      box-shadow: unset;
      :global(.ant-pagination-item) {
        background-color: #26282a;
        border-radius: 50%;
        color: #b7bfca;
        &:hover {
          background-color: #65c5c2;
        }
        a {
          background-color: #26282a;
          border-radius: 50%;
          color: #b7bfca;
          &:hover {
            background-color: #65c5c2;
            color: #26282a;
            border-color: #65c5c2;
          }
        }
        &:global(.ant-pagination-item-active) {
          border-color: #65c5c2;
          a {
            background-color: #65c5c2;
            color: #26282a;
          }
        }
      }
      :global(.ant-pagination-item-link) {
        background-color: #26282a;
        border-radius: 50%;
        color: #b7bfca;
        &:hover {
          background-color: #65c5c2;
          svg {
            fill: #26282a;
          }
        }
      }
      :global(.ant-pagination-disabled) {
        :global(.ant-pagination-item-link) {
          background-color: #26282a;
          border-radius: 50%;
          color: #b7bfca;
          &:hover {
            background-color: #26282a;
            svg {
              fill: #b7bfca;
            }
          }
        }
      }
      :global(.ant-pagination-options) {
        :global(.ant-select) {
          :global(.ant-select-selector) {
            background-color: #1f222a;
            border: 1px solid #1f222a;
            color: #fff;
          }
          :global(.ant-select-arrow) {
            color: #ffffff;
          }
          &:global(.ant-select-focused) {
            :global(.ant-select-selector) {
              background-color: #1f222a;
              border: 1px solid #1f222a;
              color: #fff;
              box-shadow: unset;
            }
          }
          :global(.ant-select-dropdown) {
            background-color: #1f222a;
            color: #35383f;
            :global(.ant-select-item) {
              color: #6e7f95;
              &:hover {
                color: #fff;
                background-color: transparent;
              }
              &:global(.ant-select-item-option-selected) {
                color: #fff;
                background-color: transparent;
              }
            }
          }
        }
      }
    }
  }
  :global(.ant-table-content) {
    overflow: unset !important;
  }

  tbody {
    tr {
      td {
        background-color: #1f2732;
        color: #fff;
        font-size: 15px;
        border-color: unset;
        border-bottom: 1px solid #2e3133;

        :global(.ant-empty-normal .ant-empty-description ){
          color: #fff;
        }
      }

      &:hover > td {
        background-color: #1f2732 !important;
        color: #fff;
        border-bottom: unset;
      }
    }
  }
}

:global(.ant-drawer-content) {
    &.contentDrawer {
        background-color: #1f2732;

        :global(.ant-drawer-header) {
            border-bottom: 1px solid #2e3133;
            padding: 8px 30px;

            :global(.ant-drawer-header-title) {
                :global(.ant-drawer-close) {
                    display: none;
                }

                :global(.ant-drawer-title) {
                    .topTitleDrawer {
                        display: flex;
                        justify-content: space-between;
                        width: 100%;
                        align-items: center;

                        h4 {
                            color: #fff;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            text-transform: capitalize;
                            line-height: 21px;
                            margin: 0;
                            display: flex;
                            align-items: center;

                            span:global(.anticon) {
                                color: #55585a;
                                margin: 0 8px;
                            }

                            span.activelink {
                                color: #ffffff;
                            }
                        }

                        .right {
                            display: flex;
                            align-items: center;
                            gap: 20px;

                            span:global(.anticon) {
                                color: #55585a;
                                padding: 4px;
                                width: 24px;
                                height: 24px;
                                display: flex;
                                align-items: center;

                                svg {
                                    width: 24px;
                                    height: 24px;
                                }
                            }
                        }
                    }
                }
            }
        }

        :global(.ant-drawer-body) {
            padding: 20px 30px;
            margin: 8px 8px 8px 0px;

            .bodyContainer {
                .userContainer {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    margin-bottom: 27px;

                    .userimg {
                        display: flex;
                        width: 32px;
                        height: 32px;
                        justify-content: center;
                        align-items: center;
                        border-radius: 12px;
                        background-color: #a9243d;
                        color: #fff;
                    }

                    h2 {
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 21px;
                        color: #fff;
                        margin: 0px;
                    }
                }

                .transactionDetailContainer {
                    background-color: #141c27;
                    border-radius: 16px;
                    padding: 24px;
                    padding-bottom: 4px;

                    .transactionDetailStyle {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-bottom: 16px;

                        h1 {
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 500;
                            color: #757575;
                            margin: 0px;
                        }

                        h2 {
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 600;
                            margin: 0px;
                            color: #f5f5f5;
                            display: flex;
                            align-items: center;
                            gap: 8px;
                        }

                        h3 {
                            color: #12b347;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 500;
                            margin: 0px;
                        }

                        h4 {
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 600;
                            margin: 0px;
                            color: #12b347;
                            display: flex;
                            align-items: center;
                            gap: 8px;
                        }

                        .statusStyle {
                            padding: 6px 10px;
                            border-radius: 6px;
                            background-color: rgba(100, 198, 193, 0.08);
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: normal;
                            letter-spacing: 0.2px;
                            color: #65c5c2;
                            width: max-content;
                        }

                        h5 {
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 140%;
                            margin: 0px;
                            color: #bdbdbd;
                            letter-spacing: 0.2px;
                        }

                        h6 {
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 140%;
                            margin: 0px;
                            color: #fff;
                            letter-spacing: 0.2px;
                        }
                    }

                    .deviderStyle {
                        border-bottom: 1px solid #4a5f7a;
                        margin: 18px 0px;
                    }
                }

                .descriptionStyle {
                    background-color: #141c27;
                    padding: 12px;
                    border-radius: 12px;
                    margin: 22px 0px;

                    p {
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 21px;
                        color: #6e7f95;
                        margin: 0px;
                    }
                    span {
                        background-color: unset !important;
                        color: #55585a !important;
                    }
                }

                .mediaContainer {
                    .carouselItemContainer {
                        .carouselItemStyle {
                            width: 166px;
                            height: 120px;
                            border-radius: 10px;
                        }
                    }

                    :global(.ant-carousel) {
                        :global(.slick-slider) {
                            :global(.slick-dots-bottom) {
                                bottom: -33px;
                            }
                        }
                    }
                }
            }
        }

        :global(.ant-drawer-footer) {
            padding: 0px;

            .footerContainer {
                display: flex;
                align-items: center;
                justify-content: end;
                gap: 17px;

                .nextBtnStyle {
                    background-color: #65c5c2;
                    border: 1px solid #65c5c2;
                    padding: 12px 24px;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 24px;
                    color: #fff;
                    height: auto;
                    border-radius: 12px;
                }
            }
        }
    }

    .bgStyle {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 480px;
        background: #141c27;
        margin: 0 auto;
        border-radius: 20px;
        flex-direction: column;
        height: 90%;
        border-radius: 20px;
        padding-bottom: 40px;
        .btnDivBlock {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
        }

        .paidBtnStyle {
            background-color: #65c5c2;
            border: 1px solid #65c5c2;
            // padding: 5px 11px;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            // line-height: 24px;
            color: #fff;

            border-radius: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;
            width: 80px;
            height: 30px;
            margin: auto;
            margin-bottom: 10px;
            margin-top: 10px;

            p {
                margin: 0;
            }
        }
    }
    .footer {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        margin-top: 10px;
    }
}

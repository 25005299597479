:global(.ant-drawer-content) {
    &.contentDrawer {
        background-color: #1f2732;

        :global(.ant-drawer-header) {
            border-bottom: 1px solid #2e3133;
            padding: 8px 30px;

            :global(.ant-drawer-header-title) {
                :global(.ant-drawer-close) {
                    display: none;
                }

                :global(.ant-drawer-title) {
                    .topTitleDrawer {
                        display: flex;
                        justify-content: space-between;
                        width: 100%;
                        align-items: center;

                        h4 {
                            color: #fff;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            text-transform: capitalize;
                            line-height: 21px;
                            margin: 0;
                            display: flex;
                            align-items: center;

                            span:global(.anticon) {
                                color: #55585a;
                                margin: 0 8px;
                            }

                            span.activelink {
                                color: #FFFFFF;
                            }
                        }


                        .right {
                            display: flex;
                            align-items: center;
                            gap: 20px;

                            span:global(.anticon) {
                                color: #55585a;
                                padding: 4px;
                                width: 24px;
                                height: 24px;
                                display: flex;
                                align-items: center;

                                svg {
                                    width: 24px;
                                    height: 24px;
                                }
                            }
                        }
                    }
                }
            }
        }


        :global(.ant-drawer-body) {
            padding: 20px 30px;

            .ContentTitle {
                h1 {
                    font-size: 20px;
                    font-weight: 500;
                    line-height: 30px;
                    color: #FFFFFF;
                    margin: 0px;
                }

                h2 {
                    color: #898F96;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 24px;
                    margin: 0px;

                    span {
                        color: #65C5C2;
                    }
                }
            }

            .contentDetailContainer {
                padding-top: 24px;
                margin-bottom: 19px;

                .lablesStyle {
                    display: flex;
                    gap: 10px;
                    align-items: center;
                    margin-bottom: 16px;

                    h1 {
                        font-size: 12px;
                        font-weight: 500;
                        line-height: 18px;
                        margin: 0px;
                        color: #55585A;
                    }

                    .labelIconStyle {
                        height: 16px;
                        width: 16px;
                    }
                }

                .featureBtnStyle {
                    margin-bottom: 16px;
                    display: flex;
                    align-items: center;
                    gap: 10px;

                    h3 {
                        height: 22px;
                        padding: 0px 8px;
                        background-color: #1E414B;
                        border-radius: 14px;
                        margin: 0px;
                        font-size: 12px;
                        font-weight: 500;
                        line-height: 18px;
                        color: #59B4D1;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    h1 {
                        font-size: 12px;
                        color: #FFFFFF;
                    }
                }

                .assigneeStyle {
                    display: flex;
                    margin-bottom: 16px;
                    margin-left: 12px;

                    .assigneeItemStyle {
                        height: 32px;
                        width: 32px;
                        border-radius: 50%;
                        overflow: hidden;
                        background-color: #2E3133;
                        border: 3px solid #2E3133;
                        margin-left: -10px;
                        color: #F75555;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        .imgStyle {
                            height: 100%;
                            width: 100%;
                        }
                    }

                    h1 {
                        font-size: 12px;
                        color: #FFFFFF;
                    }
                }

                .labelDataStyle {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 21px;
                    margin: 0px;
                    margin-bottom: 16px;
                    color: #FFFFFF;
                }
            }

            .imgVideoContainer {
                .title {
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 21px;
                    margin: 0px;
                    color: #FFFFFF;
                }

                .carouselItemContainer {
                    margin-bottom: 28px;
                    margin-top: 8px;

                    .carouselItemStyle {
                        width: 166px;
                        // min-width: 50px;
                        height: 120px;
                        // min-height: 40px;
                        border-radius: 10px;
                    }
                }

                :global(.ant-carousel) {
                    :global(.slick-slider) {
                        :global(.slick-dots-bottom) {
                            bottom: -4px;
                        }
                    }
                }
            }

            .contentDescrStyle {
                .title {
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 21px;
                    margin: 0px;
                    color: #FFFFFF;
                }

                .description {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 21px;
                    color: #898F96;
                    margin: 6px 0px;
                }
            }

            .checkBoxContainer {
                margin-bottom: 17px;
                margin-top: 30px;

                :global(.ant-checkbox-group) {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;

                    :global(.ant-checkbox-wrapper) {
                        span {
                            color: #55585A;
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 21px;
                        }

                        &:global(.ant-checkbox-wrapper-checked) {
                            span {
                                color: #FFFFFF;
                                font-size: 14px;
                                font-weight: 400;
                                line-height: 21px;
                            }

                        }

                        :global(.ant-checkbox) {
                            :global(.ant-checkbox-inner) {
                                height: 24px;
                                width: 24px;
                                border: 3px solid #65C5C2;
                                border-radius: 7px;
                                background-color: transparent;
                            }

                            &:global(.ant-checkbox-checked) {

                                :global(.ant-checkbox-inner) {
                                    height: 24px;
                                    width: 24px;
                                    border: 3px solid #65C5C2;
                                    background-color: #65C5C2;
                                }

                                &::after {
                                    border-color: #65C5C2;
                                    border-radius: 7px;
                                }
                            }
                        }

                    }
                }
            }

            .socialMediaContainer {
                .title {
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 21px;
                    margin: 0px;
                    color: #FFFFFF;
                }

                .mediaBtnContainer {
                    display: flex;
                    padding-top: 8px;
                    gap: 27px;

                    .mediaBtn {
                        padding: 6px, 10px, 6px, 10px;
                        background-color: #65C5C2;
                        border: 1px solid #65C5C2;
                        font-size: 14px;
                        font-weight: 700;
                        line-height: 22px;
                        color: #FFFFFF;
                    }
                }
            }
        }

        :global(.ant-drawer-footer) {
            padding: 0px;
        }
    }
}

.searchInput {
    width: 85%;
    border-radius: 24px;
    background-color: #262A35;
    padding: 10px 12px 9px 12px;
    border: 1px solid #262A35;
    color: #4A5F7A;

    :global(.ant-input) {
        background-color: transparent;
        color: #4A5F7A;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;

        &::placeholder {
            color: #4A5F7A;
        }
    }
}

.header {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    gap: 30;
}

.addmemberDiv {
    // color: #55585a;
    margin-top: 30px;

    // margin-right: 10;
    .addmember {
        color: #55585a;
        font-size: 14px;
        font-weight: 600;
    }

}

.memberDiv {
    display: flex;
    margin-top: 20px;
    padding: 10px;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;

}

.flex {
    display: flex;

    align-items: center;

}

.btnsStyle {
    padding: 10px !important;
    font-size: 14px !important;
    line-height: 20px !important;
}

.pictureStyle {
    min-height: 40px;
    height: 40px;
    min-width: 40px;
    width: 40px;
    background-color: #1E414B;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #F75555;
    background-color: #2E3133;
    border: 3px solid #2E3133;
}

.name {
    font-size: 14px;
    color: #FFFFFF;
    font-weight: 500;
    margin-bottom: 2px;
    margin-top: 0px;

}

.email {
    font-size: 10px;
    color: #898F96;
    margin-bottom: 0;

}

:global(.ant-drawer-footer) {
    padding: 10px 30px 30px 30px !important;

    .btnblock {
        display: flex;
        justify-content: end;
        align-items: center;
        gap: 10px;
        margin-bottom: 20;


    }

}

.selectedItem {
    background-color: #262A35;
    border-radius: 10px;
    /* Replace this color with the color you want for the selected item background */
}
.mainSalesContainer {
    .filtersSummaryContainer {
        border-radius: 10px;
        padding: 20px 15px;
        background-color: #1f2732;
        margin-bottom: 15px;

        .filtersContainer {
            padding-right: 10px 14px;

            h1 {
                margin: 0px;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px;
                color: #fff;
                margin-bottom: 10px;
            }

            .filterStyle {
                :global(.ant-select) {
                    &.selectorStyle {
                        width: 100% !important;
                        min-height: 40px;
                        margin-bottom: 10px;

                        :global(.ant-select-selector) {
                            background-color: #1f222a !important;
                            border: 1px solid #2e3133;
                            color: #ffffff;
                            min-height: 40px;
                            display: flex;
                            align-items: center;
                            border-radius: 12px;

                            input {
                                color: #fff;
                                height: 100%;
                            }

                            :global(.ant-select-selection-placeholder) {
                                color: #55585a;
                            }
                        }

                        &:global(.ant-select-focused) {
                            :global(.ant-select-selector) {
                                box-shadow: unset !important;
                                border-color: #4a5f7a !important;
                            }
                        }

                        &:hover {
                            :global(.ant-select-selector) {
                                box-shadow: unset !important;
                                border-color: #4a5f7a !important;
                            }
                        }
                    }
                }

                :global(.ant-col) {
                    &:nth-child(2) {
                        padding-right: 10px;
                    }

                    &:nth-child(3) {
                        padding-left: 10px;
                    }
                }

                .inputStyle {
                    border: 1px solid #2e3133;
                    font-size: 18px;
                    font-style: normal;
                    min-height: 48px;
                    font-weight: 500;

                    &::placeholder {
                        color: #55585a;
                        font-size: 14px;
                    }
                }

                :global(.ant-picker) {
                    &.dateSelectStyle {
                        background-color: #1f222a;
                        border: 1px solid #2e3133;
                        color: #ffffff;
                        min-height: 48px;
                        display: flex;
                        align-items: center;
                        border-radius: 12px;

                        :global(.ant-picker-separator) {
                            color: #55585a;
                        }

                        :global(.ant-picker-suffix) {
                            color: #55585a;
                        }

                        :global(.ant-picker-input > input) {
                            color: #ffffff;

                            &::placeholder {
                                color: #55585a;
                            }
                        }

                        :global(.ant-picker-input) {
                            :global(.ant-picker-suffix) {
                                color: #ffffff;
                            }
                        }
                    }
                }
            }
        }

        .summaryContainer {
            padding-left: 10px;

            h1 {
                margin: 0px;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px;
                color: #fff;
                margin-bottom: 8px;
            }

            .summaryStyle {
                display: flex;
                align-items: center;
                border: 1px solid #2e3133;
                background-color: #1f222a;
                border-radius: 10px;

                .summarydata {
                    padding: 25px 15px;
                    width: 100%;
                    border-right: 1px solid #2e3133;

                    h1 {
                        color: #fff;
                        font-size: 17px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 21px;
                        @media (max-width: 480px) {
                            font-size: 14px;
                        }
                    }

                    h2 {
                        color: #fff;
                        margin: 0px;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 21px;
                        @media (max-width: 480px) {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
}

.dropdownstyle {
    background: #1f222a;
    border: 1px solid #6e7f95;

    :global(.ant-select-item) {
        color: #6e7f95;
        border-radius: 0px;

        &:not(:last-child) {
            border-bottom: 1px solid #6e7f95;
        }

        &:hover {
            color: #fff;
        }
    }
}

@font-face {
    font-family: "Archivo";
    src: url("Archivo-Light.eot");
    src:
        url("Archivo-Light.eot?#iefix") format("embedded-opentype"),
        url("Archivo-Light.woff2") format("woff2"),
        url("Archivo-Light.woff") format("woff"),
        url("Archivo-Light.ttf") format("truetype"),
        url("Archivo-Light.svg#Archivo-Light") format("svg");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Archivo";
    src: url("Archivo-Regular.eot");
    src:
        url("Archivo-Regular.eot?#iefix") format("embedded-opentype"),
        url("Archivo-Regular.woff2") format("woff2"),
        url("Archivo-Regular.woff") format("woff"),
        url("Archivo-Regular.ttf") format("truetype"),
        url("Archivo-Regular.svg#Archivo-Regular") format("svg");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Archivo";
    src: url("Archivo-Medium.eot");
    src:
        url("Archivo-Medium.eot?#iefix") format("embedded-opentype"),
        url("Archivo-Medium.woff2") format("woff2"),
        url("Archivo-Medium.woff") format("woff"),
        url("Archivo-Medium.ttf") format("truetype"),
        url("Archivo-Medium.svg#Archivo-Medium") format("svg");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Archivo";
    src: url("Archivo-SemiBold.eot");
    src:
        url("Archivo-SemiBold.eot?#iefix") format("embedded-opentype"),
        url("Archivo-SemiBold.woff2") format("woff2"),
        url("Archivo-SemiBold.woff") format("woff"),
        url("Archivo-SemiBold.ttf") format("truetype"),
        url("Archivo-SemiBold.svg#Archivo-SemiBold") format("svg");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Archivo";
    src: url("Archivo-Bold.eot");
    src:
        url("Archivo-Bold.eot?#iefix") format("embedded-opentype"),
        url("Archivo-Bold.woff2") format("woff2"),
        url("Archivo-Bold.woff") format("woff"),
        url("Archivo-Bold.ttf") format("truetype"),
        url("Archivo-Bold.svg#Archivo-Bold") format("svg");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Archivo";
    src: url("Archivo-ExtraBold.eot");
    src:
        url("Archivo-ExtraBold.eot?#iefix") format("embedded-opentype"),
        url("Archivo-ExtraBold.woff2") format("woff2"),
        url("Archivo-ExtraBold.woff") format("woff"),
        url("Archivo-ExtraBold.ttf") format("truetype"),
        url("Archivo-ExtraBold.svg#Archivo-ExtraBold") format("svg");
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Archivo";
    src: url("Archivo-Black.eot");
    src:
        url("Archivo-Black.eot?#iefix") format("embedded-opentype"),
        url("Archivo-Black.woff2") format("woff2"),
        url("Archivo-Black.woff") format("woff"),
        url("Archivo-Black.ttf") format("truetype"),
        url("Archivo-Black.svg#Archivo-Black") format("svg");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

* {
    font-family: "Archivo";
    font-weight: 400;
}

.contentDataContainer {
    .contentDataStyle {
        background-image: url(../../../assets/Images/contentBG.png);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        align-items: center;
        text-align: center;
        padding: 50px 0px;

        h1 {
            font-size: 28px;
            font-style: normal;
            font-weight: 500;
            line-height: 42px;
            margin: 0px;
            color: #1b1d1f;
        }

        p {
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            margin: 0px;
            color: #727880;
        }

        :global(.ant-select) {
            &.selectStyle {
                :global(.ant-select-clear){
                margin-right: 8px;
                top: 25px;
                svg{
                    width: 20px;
                    height: 20px;
                }
                }
                margin-top: 20px;
                text-align: left;
                @media (min-width: 992px) {
                    width: 400px;
                }
                @media (min-width: 600px) and (max-width: 991px) {
                    width: 350px;
                }
                @media (max-width: 599px) {
                    width: 90%;
                }
                :global(.ant-select-arrow) {
                    color: #fff;
                }
                :global(.ant-select-selector) {
                    background-color: #1f222a !important;
                    border: 1px solid #1f222a;
                    color: #ffffff;
                    display: flex;
                    align-items: center;
                    border-radius: 12px;
                    padding: 13px 20px;
                    height: auto;

                    input {
                        color: #fff;
                        height: 100%;
                        padding: 10px 10px;
                    }

                    :global(.ant-select-selection-placeholder) {
                        color: #fff;
                    }
                }

                &:global(.ant-select-focused) {
                    :global(.ant-select-selector) {
                        box-shadow: unset !important;
                        border-color: #4a5f7a !important;
                    }
                }

                &:hover {
                    :global(.ant-select-selector) {
                        box-shadow: unset !important;
                        border-color: #4a5f7a !important;
                    }
                }
            }
        }
    }

    .popularContainer {
        padding: 20px 30px;
        gap: 12px;

        h1 {
            border-bottom: 1px solid #65c5c2;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 160%;
            color: #65c5c2;
            width: 50%;
            padding-bottom: 10px;
            margin: 0px;
            margin-bottom: 8px;

            span {
                float: right;
                margin-right: 5px;
                padding: 0px;
                cursor: pointer;
            }
        }

        h2 {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 21px;
            color: #fff;
            cursor: pointer;
        }
    }
}

.selectDropdownstyle {
    background: #1f222a;
    border: 1px solid #6e7f95;

    :global(.ant-select-item) {
        color: #6e7f95;
        border-radius: 0px;

        &:not(:last-child) {
            border-bottom: 1px solid #6e7f95;
        }

        &:hover {
            color: #fff;
        }
    }
}

.graphChartContainer {
    :global(.apexcharts-canvas) {
        :global(.apexcharts-toolbar) {
            display: none;
        }
    }
}

:global(.ant-picker) {
    &.dateSelectStyle {
        background-color: #1f222a;
        border: 1px solid #2e3133;
        color: #ffffff;
        min-height: 48px;
        display: flex;
        align-items: center;
        border-radius: 12px;

        :global(.ant-picker-separator) {
            color: #55585a;
        }

        :global(.ant-picker-suffix) {
            color: #55585a;
        }

        :global(.ant-picker-input >input) {
            color: #ffffff;

            &::placeholder {
                color: #55585a;
            }
        }

        :global(.ant-picker-input) {
            :global(.ant-picker-suffix) {
                color: #ffffff;
            }
        }
    }
}

.mainTransactionsContainer {
    .transactionsCardsContainer {
        padding: 20px 0px;
        display: flex;
        gap: 10px;
        overflow-x: scroll;

        .transcationCardStyle {
            border-radius: 20px;
            padding: 16px;
            min-width: 238px;
            width: 100%;
            overflow: hidden;
            position: relative;

            .maskStyle {
                position: absolute;
                height: 150px;
                right: 0px;
                top: 0px;
            }

            .valueContainer {
                display: flex;

                h1 {
                    font-size: 16.343px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    color: #fff;
                    margin: 0px;
                    width: 90%;

                    span {
                        font-size: 8.171px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 14.709px;
                    }
                }
            }

            .cardNumContainer {
                display: flex;
                gap: 25px;
                align-items: center;
                padding-top: 22px;
                padding-bottom: 14px;

                h1 {
                    font-size: 14.709px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    margin: 0px;
                    color: #fff;
                }
            }

            .cardNameContainer {
                display: flex;
                align-items: center;

                h1 {
                    font-size: 12.257px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    margin: 0px;
                    color: #fff;
                    width: 80%;
                }

                .expireContainer {
                    width: 20%;

                    h2 {
                        font-size: 11.44px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 17.16px;
                        color: #fff;
                        margin: 0px;
                        text-align: end;
                    }

                    h3 {
                        font-size: 8.171px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 14.709px;
                        margin: 0px;
                        color: #fff;
                        text-align: end;
                    }
                }
            }
        }
    }

    .filtersContainer {
        padding: 24px 0px;
        display: flex;
        align-items: center;

        .searchInput {
            width: 350px;
            background-color: #141c27;
            padding: 10px 12px 9px 12px;
            border-radius: 24px;
            border: 1px solid #141c27;

            :global(.ant-input) {
                background-color: #141c27;
                color: #4a5f7a;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 21px;

                &::placeholder {
                    color: #4a5f7a;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 21px;
                }
            }

            :global(.ant-input-prefix) {
                color: #4a5f7a;
                margin-right: 12px;
            }
        }

        .dropdownFilterContainer {
            width: 100%;
            justify-content: end;
            display: flex;
            gap: 24px;

            .dropdownFilterStyle {
                display: flex;
                align-items: center;
                gap: 5px;

                h1 {
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 18px;
                    color: #55585a;
                    margin: 0px;
                }

                .selectStyle {
                    :global(.ant-select-selector) {
                        background-color: black;
                    }
                }
            }
        }
    }

    .chartScheduleContainer {
        padding-top: 35px;

        @media (min-width: 992px) {
            padding-left: 60px;
        }

        // .chartContainer {
        //     background-color: #141c27;
        //     padding: 24px;
        //     border-radius: 12px;
        //     margin-bottom: 16px;
        //     height: auto;

        //     .titleContainer {
        //         display: flex;
        //         align-items: center;

        //         h1 {
        //             font-size: 16px;
        //             font-style: normal;
        //             font-weight: 600;
        //             line-height: 24px;
        //             margin: 0px;
        //             color: #fff;
        //             width: 94%;
        //         }

        //         img {
        //             cursor: pointer;
        //         }
        //     }

        //     h2 {
        //         font-size: 10px;
        //         font-style: normal;
        //         font-weight: 400;
        //         line-height: 15px;
        //         margin: 0px;
        //         color: #898f96;
        //     }
        // }

        .schedulePaymentContainer {
            background-color: #141c27;
            padding: 24px;
            border-radius: 12px;
            border: 1px solid #5c6f87;
            border-radius: 12px;

            h1 {
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px;
                margin: 0px;
                margin-bottom: 8px;
                color: #fff;
            }

            .scheduleStyle {
                border: 1px solid #5c6f87;
                border-radius: 12px;
                background-color: #141c27;
                padding: 16px;
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                gap: 16px;
                margin-bottom: 5px;

                .imgContainer {
                    min-height: 42px;
                    height: 42px;
                    min-width: 42px;
                    width: 42px;
                    border-radius: 12px;
                    color: white;
                    background-color: #a9243d;
                    overflow: hidden;
                    justify-content: center;
                    display: flex;
                    align-items: center;

                    img {
                        height: 43px;
                        width: 43px;
                    }
                }

                .titleContainer {
                    flex-grow: 1;

                    h1 {
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 21px;
                        margin: 0px;
                        margin-bottom: 6px;
                        color: #fff;
                    }

                    h3 {
                        border-radius: 14px;
                        background: rgba(100, 198, 193, 0.08);
                        display: flex;
                        padding: 2px 8px;
                        align-items: center;
                        gap: 4px;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 18px;
                        color: #898f96;
                        margin: 0px;
                        width: max-content;
                    }
                }

                h2 {
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 18px;
                    margin: 0px;
                    width: 22%;
                    text-align: end;
                    color: #fff;
                }
            }
        }
    }

    .transactionsContainer {
        .summaryContainer {
            margin-bottom: 20px;
            background-color: #141c27;
            border: 1px solid #5c6f87;
            border-radius: 12px;

            .totalSummaryStyle {
                padding: 20px;
                text-align: center;
                border-top: 1px solid #5c6f87;

                h2 {
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 21px;
                    margin: 0px;
                    margin-bottom: 6px;
                    color: #fff;
                }
            }

            .paidUnpaidContainer {
                display: flex;
                text-align: center;
                align-items: center;

                .summaryStyle {
                    width: 50%;
                    padding: 20px;

                    &:nth-child(1) {
                        border-right: 1px solid #5c6f87;
                    }

                    h2 {
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 21px;
                        margin: 0px;
                        margin-bottom: 6px;
                        color: #fff;
                    }
                }
            }
        }

        .menuContainer {
            display: flex;
            margin-bottom: 20px;
            align-items: center;
            gap: 28px;

            .menuStyle {
                display: flex;
                gap: 10px;
                align-items: center;
                cursor: pointer;

                h1 {
                    margin: 0px;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 18px;
                }

                .valueStyle {
                    display: flex;
                    padding: 0px 6px;
                    align-items: center;
                    border-radius: 6px;
                    height: 24px;
                }
            }
        }

        .paymentCardsContainer {
            padding-bottom: 20px;
            display: flex;
            gap: 10px;

            .paymentCardStyle {
                border-radius: 20px;
                padding: 16px;
                min-width: 238px;
                width: 100%;
                overflow: hidden;

                .maskStyle {
                    position: absolute;
                    height: 150px;
                    right: 0px;
                    top: 0px;
                }

                .valueContainer {
                    display: flex;

                    h1 {
                        font-size: 16.343px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        color: #fff;
                        margin: 0px;
                        width: 90%;

                        span {
                            font-size: 8.171px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 14.709px;
                        }
                    }
                }

                .cardNumContainer {
                    display: flex;
                    gap: 25px;
                    align-items: center;
                    padding-top: 22px;
                    padding-bottom: 14px;

                    h1 {
                        font-size: 14.709px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        margin: 0px;
                        color: #fff;
                    }
                }

                .cardNameContainer {
                    display: flex;
                    align-items: center;

                    h1 {
                        font-size: 12.257px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        margin: 0px;
                        color: #fff;
                        width: 80%;
                    }

                    .expireContainer {
                        width: 20%;

                        h2 {
                            font-size: 11.44px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 17.16px;
                            color: #fff;
                            margin: 0px;
                            text-align: end;
                        }

                        h3 {
                            font-size: 8.171px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 14.709px;
                            margin: 0px;
                            color: #fff;
                            text-align: end;
                        }
                    }
                }
            }
        }


        h1 {
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            color: #fff;
            margin: 0px;

        }

        .transactionContainer {
            max-height: 485px;
            overflow: auto;
            padding-right: 10px;
            margin-top: 10px;

            .transactionsStyle {
                border: 1px solid #5c6f87;
                border-radius: 12px;
                background-color: #141c27;
                padding: 16px;
                cursor: pointer;
                margin-bottom: 5px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-wrap: wrap;
                gap: 16px;

                .receiptContainer {
                    display: flex;
                    align-items: center;

                    .receiptStyle {
                        background-color: rgba(100, 198, 193, 0.08);
                        padding: 8px;
                        border-radius: 12px;
                        margin-right: 8px;
                    }
                }

                h2 {
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 18px;
                    color: #fff;
                    margin: 0px;
                }

                .userContainer {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    min-width: 140px;

                    .userimg {
                        display: flex;
                        width: 32px;
                        height: 32px;
                        justify-content: center;
                        align-items: center;
                        border-radius: 12px;
                        background-color: #a9243d;
                        color: #fff;
                    }

                    h2 {
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 18px;
                        color: #fff;
                        margin: 0px;
                    }
                }

                h3 {
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 18px;
                    color: #898f96;
                    width: 12%;
                }

                .statusContainer {
                    width: 10%;

                    .statusStyle {
                        font-size: 10px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 160%;
                        margin: 0px;
                        padding: 6px 10px;
                        text-align: center;
                        border: 1px solid;
                        width: max-content;
                        min-height: max-content;
                        border-radius: 6px;
                    }
                }

                .valueContainer {
                    display: flex;
                    align-items: center;
                    gap: 16px;

                    h1 {
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 21px;
                        color: #fff;
                        margin: 0px;
                    }

                    img {
                        cursor: pointer;
                    }
                }
            }
        }
    }

}

:global(.ant-picker-dropdown) {
    &.rangeStyle {
        :global(.ant-picker-cell-selected) {

            &:before {
                background-color: #181a20 !important;
            }

            :global(.ant-picker-cell-inner) {
                background-color: #181a20 !important;
                color: white !important;
            }
        }

        :global(.ant-picker-cell-in-view.ant-picker-cell-in-range) {
            &:before {
                background-color: #181a20 !important;
            }
        }

        :global(.ant-picker-cell-range-end) {
            &:before {
                background-color: #181a20 !important;
            }

            :global(.ant-picker-cell-inner) {
                background-color: #181a20 !important;
            }
        }
    }
}
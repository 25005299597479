.mainLayout {
    min-height: 100vh;
    background-color: #181a20;
    .mainbodyWrapper {
        background-color: #181a20;
        // min-height: calc(100vh - 74px);
        // height: 100%;
        overflow: auto;
        padding: 20px;
        max-height: calc(100vh - 74px);
    }
}
// Scroll bar Styling Custom
/* width */
::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #65c5c2;
    border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #65c5c2;
}

//   scrollbar styling end

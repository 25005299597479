.headerSection {
	padding: 20px 35px;
	background-color: #1f222a;
	max-height: 80px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	box-shadow:
		0px,
		4px #888888;
	@media (max-width: 350px) {
		flex-direction: column;
	}

	.HeadingBlock {
		display: flex;
		align-items: center;
		gap: 8px;
		.menuIcon {
			width: 20px;
			height: 20px;
			display: none;
			@media (max-width: 991px) {
				display: block;
			}
		}
		.mainHeading {
			font-weight: 500;
			font-size: 14px;
			line-height: 21px;
			color: #ffffff;
			margin: 0;
		}
		.subHeading {
			font-weight: 500;
			font-size: 14px;
			line-height: 21px;
			color: #55585a;
			margin: 0;
		}
	}
	.rightSide {
		display: flex;
		align-items: center;
		gap: 24px;
		.avatar {
			.profile {
				height: 34px;
				width: 34px;
				border-radius: 50%;
				background-color: #65c5c2;
				font-weight: 400;
				margin-right: 12px;
				text-transform: uppercase;
				cursor: pointer;
				color: #333333;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 13px;
			}

			.title {
				font-size: 24px;
				font-weight: 500;
				margin: 8px 0 18px;
			}
		}
	}
}

.managementContainer {
  background-color: #181a20;
  // padding: 50px;

  // overflow: hidden !important;
  // height: calc(100vh - 120px);

  @media (max-width: 1280px) {
    padding-left: 10px;
  }

  @media (max-width: 992px) {
    max-height: calc(100vh - 80px);
    padding: 40px !important;
  }

  @media (max-width: 576px) {
    padding: 20px !important;
  }

}

.teamemberTableSkelton {
  margin-top: 40px;
  :global(.ant-table) {
    background-color: transparent;
    table {
      border-spacing: 0 5px;
      padding: 10px;
      background-color: transparent;
      :global(.ant-table-thead) {
        :global(.ant-table-cell) {
          background: transparent;
          border: 0px solid #262a35;
          color: #8d8d8d;
          &::before {
            display: none;
          }
        }
      }
      :global(.ant-table-tbody) {
        :global(.ant-table-row) {
          :global(.ant-table-cell) {
            border: 1px solid #262a35;
            background-color: #1f2732;
            border-left-width: 0px;
            border-right-width: 0px;
            overflow: hidden;
            margin-bottom: 10px;
            &:first-child {
              border-left-width: 1px;
              border-radius: 12px 0 0 12px;
            }
            &:last-child {
              margin-bottom: 5px;
              border-right-width: 1px;
              border-radius: 0 12px 12px 0;
            }
            &::before {
              background-color: transparent;
              width: 0px;
              height: 0px;
              display: none;
            }
          }
        }
      }
    }
  }
}

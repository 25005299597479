.SaleschartContainer {
    height: 100%;

    :global(.apexcharts-canvas) {
        :global(.apexcharts-toolbar) {
            display: none;
        }
    }

    .noDataStyle {
        font-size: 15px;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
    }
}

.bubbleChartContainer {
    :global(.apexcharts-canvas) {
        :global(.apexcharts-toolbar) {
            display: none;
        }
    }

    .noDataStyle {
        font-size: 15px;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
    }
}

.donutChartContainer {
    :global(.apexcharts-canvas) {
        margin: auto;
    }

    .noDataStyle {
        font-size: 15px;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
    }
}

.noDataStyle {
    font-size: 15px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
}
// padding: 20px 20px 30px 20px;
// background-color: #181a20;
// min-height: calc(100vh - 95px);
// height: 100%;
// overflow: auto;
.dashboardWrapper {
    .titleLine {
        border-bottom: 1px solid #65c5c2;
        padding: 10px 0;
        display: flex;
        align-items: center;

        h1 {
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            color: #fff;
            margin: 0;
            width: 100%;
        }

        .dashBoadSelect {
            width: 100%;
            text-align: right;

            h2 {
                color: #65C5C2;
                font-size: 16px;
                font-weight: 500;
                line-height: 160%;
                letter-spacing: 0.2px;
                margin: 0px;
                margin-right: 15px;
            }

            :global(.ant-select) {
                width: 132px;
                text-align: left;

                :global(.ant-select-selector) {
                    background-color: transparent !important;
                    border: 0px solid !important;
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 160%;
                    letter-spacing: 0.2px;

                    :global(.ant-select-selection-item) {
                        color: #65C5C2;
                    }
                }
            }

            .ant-select-dropdown {
                background-color: #1F222A;
                color: #35383f;
            }

            .arrowStyle {
                color: #65C5C2;
                margin-left: 15px;

                svg {
                    height: 24px;
                    width: 24px;
                }
            }
        }
    }

    .pageTitle {
        h1 {
            color: #fff;
            font-size: 20px;
            line-height: 25px;
            font-weight: 500;
            margin: 0 0 5px 0;
        }

        p {
            font-size: 12px;
            line-height: 15px;
            font-weight: 400;
            color: #55585a;
            margin-bottom: 0;
        }
    }

    :global(.ant-picker) {
        &.dateSelectStyle {
            background-color: #1f222a;
            border: 1px solid #2e3133;
            color: #ffffff;
            min-height: 48px;
            display: flex;
            align-items: center;
            border-radius: 12px;

            :global(.ant-picker-separator) {
                color: #55585a;
            }

            :global(.ant-picker-suffix) {
                color: #55585a;
            }

            :global(.ant-picker-input >input) {
                color: #ffffff;

                &::placeholder {
                    color: #55585a;
                }
            }

            :global(.ant-picker-input) {
                :global(.ant-picker-suffix) {
                    color: #ffffff;
                }
            }
        }
    }

    .cardCOunter {
        display: flex;
        align-items: center;
        gap: 12px;
        border-radius: 10px;
        border-bottom: 1px solid #4a5f7a;
        background: #141c27;
        padding: 16px 10px;
        margin-bottom: 25px;

        .content {
            h4 {
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                margin: 0 0 5px 0;
                color: #fff;
            }

            h1 {
                display: flex;
                margin: 0;
                color: #fff;
                align-items: center;
                flex-wrap: wrap;
                font-size: 32px;
                font-style: normal;
                font-weight: 400;
                line-height: 36px;

                span {
                    font-size: 33px;
                }

                p {
                    padding-left: 8px;
                    margin: 0;
                    color: #757575;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 18px;
                }
            }
        }
    }

    .engagementRate {
        .card {
            padding: 24px;

            &:nth-child(even) {
                border-left: 1px solid #424242;
                border-right: 1px solid #424242;
                border-bottom: 1px solid #424242;
            }

            &:nth-child(odd) {
                border-bottom: 1px solid #424242;
            }

            .icon {
                display: flex;
                align-items: center;
                gap: 8px;
                margin-bottom: 8px;

                p {
                    margin: 0;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    color: #fafafa;
                }
            }

            .content {
                display: flex;
                align-items: center;
                gap: 8px;

                h2 {
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 30px;
                    color: #fff;
                    margin: 0;
                }

                h5 {
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 18px;
                    color: #65c5c2;
                    padding: 2px 8px;
                    border-radius: 14px;
                    background: rgba(100, 198, 193, 0.08);
                    margin: 0;
                }
            }
        }
    }

    .totalClicks {
        border: 1px solid #4a5f7a;
        padding: 24px;
        height: 100%;
    }

    .saleReport {
        border: 1px solid #4a5f7a;
        padding: 5px;
        height: 100%;
    }

    .grapgCol {
        display: flex;
        flex-direction: column;
    }

    .LinkReport {
        padding: 24px;
        border: 1px solid #4a5f7a;

        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            height: 260px;
            overflow-y: auto;
            overflow-x: hidden;

            .noDataStyle {
                font-size: 15px;
                height: 100px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #fff;
            }

            li {
                margin: 9px 0px;

                .liStyle {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-right: 10px;

                    p {
                        margin: 0;
                        color: #fff;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                    }

                    .decStyle {
                        color: white;
                        cursor: pointer;
                        width: 85%;
                        overflow-wrap: break-word;
                    }
                }
            }
        }
    }

    .registerdUsers {
        padding: 5px 10px;
        display: flex;
        gap: 15px;

        .item {
            flex-shrink: 2;
            flex-grow: 1;
            padding: 24px;
            border-radius: 12px;

            h1 {
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
                line-height: 30px;
                color: #f0f9f9;
                margin-top: 0;
                margin-bottom: 10px;
            }

            h2 {
                color: #f0f9f9;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
                margin-top: 0;
                margin-bottom: 10px;
            }

            h4 {
                color: #f0f9f9;
                display: flex;
                align-items: center;
                gap: 4px;
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                line-height: 18px;
                margin-top: 0;
                margin-bottom: 10px;
            }

            &:nth-child(1) {
                background-color: rgba(100, 198, 193, 0.08);
            }

            &:nth-child(2) {
                background-color: rgba(36, 107, 253, 0.08);
            }
        }
    }

    .dashboardMain {
        margin-top: 15px;

        .dashboardChart {
            // height: 320px;
            border-radius: 12px;
            border: 1px solid #35383f;
            padding: 10px;
            gap: 24px;
            background: #141c27;

            h1 {
                color: #fff;
                margin-left: 15px;
            }
        }
    }
}

.selectDropdownstyle {
    background: #1f222a;
    border: 1px solid #6e7f95;

    :global(.ant-select-item) {
        color: #6e7f95;
        border-radius: 0px;

        &:not(:last-child) {
            border-bottom: 1px solid #6e7f95;
        }

        &:hover {
            color: #fff;
        }
    }
}

:global(.ant-picker-dropdown) {
    &.rangeStyle {
        :global(.ant-picker-cell-selected) {

            &:before {
                background-color: #181a20 !important;
            }

            :global(.ant-picker-cell-inner) {
                background-color: #181a20 !important;
                color: white !important;
            }
        }

        :global(.ant-picker-cell-in-view.ant-picker-cell-in-range) {
            &:before {
                background-color: #181a20 !important;
            }
        }

        :global(.ant-picker-cell-range-end) {
            &:before {
                background-color: #181a20 !important;
            }

            :global(.ant-picker-cell-inner) {
                background-color: #181a20 !important;
            }
        }
    }
}
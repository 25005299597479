:global(.ant-drawer-content) {
    &.contentUserDrawer {
        background-color: #1f2732;

        :global(.ant-drawer-header) {
            border-bottom: 1px solid #2e3133;
            padding: 8px 30px;

            :global(.ant-drawer-header-title) {
                :global(.ant-drawer-close) {
                    display: none;
                }

                :global(.ant-drawer-title) {
                    .topTitleDrawer {
                        display: flex;
                        justify-content: space-between;
                        width: 100%;
                        align-items: center;

                        h4 {
                            color: #fff;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 21px;
                            margin: 0;
                            display: flex;
                            align-items: center;

                            span:global(.anticon) {
                                color: #55585a;
                                margin: 0 8px;
                            }

                            span.activelink {
                                color: #FFFFFF;
                            }
                        }

                        .right {
                            display: flex;
                            align-items: center;
                            gap: 20px;

                            span:global(.anticon) {
                                color: #55585a;
                                padding: 4px;
                                width: 24px;
                                height: 24px;
                                display: flex;
                                align-items: center;

                                svg {
                                    width: 24px;
                                    height: 24px;
                                }
                            }
                        }
                    }
                }
            }
        }

        :global(.ant-drawer-body)::-webkit-scrollbar {
            width: 6px;
        }

        :global(.ant-drawer-body)::-webkit-scrollbar-track {
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            border-radius: 5px;
        }

        :global(.ant-drawer-body)::-webkit-scrollbar-thumb {
            background-color: #121617;
            outline: 1px solid #121617;
            border-radius: 5px;
        }


        :global(.ant-drawer-body) {
            padding: 20px 30px;
            margin: 8px 8px 8px 0px;

            .inputField {
                border: 1px solid #1F222A;
                background: #1F222A;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;

                &::placeholder {
                    color: #55585a;
                    font-size: 12px;
                    text-align: left;
                }

            }

            .lableLight {
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                line-height: 18px;
                color: #ffffff;
                margin: 24px 0 5px 0;

                &.white {
                    color: #fff;
                    font-size: 14px;
                    margin-bottom: 8px;
                }

                &.socialShareStyle {
                    margin-top: 55px;
                }
            }

            .textAreaStyle {
                background-color: #1F222A;
                border: 1px solid #1F222A;
                color: #FFF;
                min-height: 100px;
                padding: 18px;

                &::placeholder {
                    color: gray;
                }

            }
        }

        :global(.ant-drawer-footer) {
            padding: 10px 30px 30px 30px;

            .btnblock {
                display: flex;
                justify-content: end;
                align-items: center;
                gap: 10px;

                .cancelButton {
                    background-color: transparent;
                }
            }
        }

        :global(.ant-picker) {
            &.dateSelectStyle {
                background-color: #1f222a;
                border: 1px solid #1f222a;
                color: #ffffff;
                min-height: 40px;
                display: flex;
                align-items: center;
                border-radius: 12px;

                :global(.ant-picker-input >input) {
                    color: #ffffff;

                    &::placeholder {
                        color: #ffffff;
                    }
                }

                :global(.ant-picker-input) {
                    :global(.ant-picker-suffix) {
                        color: #ffffff;
                    }
                }
            }
        }

        .adduserDetails {
            display: flex;
            flex-direction: column;
            gap: 20px;

            .btnStyle {
                .active {
                    padding: 2px 8px;
                    border-radius: 14px;
                    background-color: #F5F5F5;
                    font-size: 12px;
                    font-style: normal;
                    cursor: pointer;
                    font-weight: 500;
                    line-height: 18px;
                    color: #727880;
                }

                .deActive {
                    padding: 2px 8px;
                    border-radius: 14px;
                    background-color: #1E414B;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    cursor: pointer;
                    line-height: 18px;
                    color: #59B4D1;
                }
            }
        }

        :global(.ant-select) {
            &.drawerselect {
                width: 100% !important;
                min-height: 40px;

                :global(.ant-select-selector) {
                    background-color: #1f222a;
                    border: 1px solid #1f222a;
                    color: #ffffff;
                    min-height: 40px;
                    display: flex;
                    align-items: center;
                    border-radius: 12px;

                    input {
                        color: #fff;
                        height: 100%;
                    }

                    :global(.ant-select-selection-placeholder) {
                        color: #55585a;
                    }
                }

                &:global(.ant-select-focused) {
                    :global(.ant-select-selector) {
                        box-shadow: unset !important;
                        border-color: #4a5f7a !important;
                    }
                }

                &:hover {
                    :global(.ant-select-selector) {
                        box-shadow: unset !important;
                        border-color: #4a5f7a !important;
                    }
                }
            }
        }

    }
}
.SalesSkeletonStyle {
    display: flex;
    align-items: center;

    .title {
        margin-left: 10px;
    }

    .values {
        margin-top: 10px;
        display: flex;
        align-items: center;
        gap: 20px;
    }
}

.engagementRateSkeleton {
    .titleStyle {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    .values {
        display: flex;
        align-items: center;
        margin-top: 10px;
        gap: 10px;
    }
}

.linksReportSkeleton {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;

    .description {
        width: 100%;
    }
}
.salesTableContainer {
    .statusStyle {
        border-radius: 15px;
        padding: 2px 15px;
        width: max-content;
        text-transform: capitalize;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
    }

    .btnContainer {
        display: flex;
        gap: 15px;

        .acceptBtn {
            border-radius: 6px;
            border: 1px solid #65c5c2;
            background-color: transparent;
            padding: 6px 10px;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: 160%;
            color: #65c5c2;

            &:hover {
                border: 1px solid #65c5c2 !important;
                color: #65c5c2 !important;
            }
        }

        .rejectBtn {
            border-radius: 6px;
            border: 1px solid #f54336;
            background-color: transparent;
            padding: 6px 10px;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: 160%;
            color: #f54336;

            &:hover {
                border: 1px solid #f54336 !important;
                color: #f54336 !important;
            }
        }
    }

    :global(.ant-table) {
        :global(.ant-table-container) {
            width: 100%;
            overflow: auto;
            max-height: 560px;
            @media (max-width: 1000px) {
                white-space: nowrap;
            }
            @media (max-width: 991px) {
                max-height: 400px;
            }
        }
        table {
            thead {
                tr {
                    th {
                        background-color: #1f2732;
                        color: #fff;
                        font-size: 16px;
                        padding: 30px;
                        border-bottom: 1px solid #2e3133;
                        @media (max-width: 1000px) {
                            white-space: nowrap;
                        }
                    }

                    :global(.ant-table-cell::before) {
                        background-color: #1f2732 !important;
                    }
                }
            }

            tbody {
                tr {
                    td {
                        background-color: #1f2732;
                        color: #fff;
                        font-size: 15px;
                        padding: 30px;
                        border-bottom: 1px solid #2e3133;
                        @media (max-width: 1000px) {
                            white-space: nowrap;
                        }

                        :global(.ant-empty-normal .ant-empty-description ){
                            color: #fff;
                        }
                    }

                    &:hover > td {
                        background-color: #1f2732 !important;
                        color: #fff;
                    }
                }
            }
        }
    }

    :global(.ant-table-pagination.ant-pagination) {
        :global(.ant-pagination-item-link) {
            background-color: #26282a;
            color: #b7bfca;
            border-radius: 50%;
        }

        :global(.ant-pagination-item) {
            background-color: #26282a;
            border-radius: 50%;
            border-color: #26282a;
        }

        :global(.ant-pagination-item a) {
            color: #b7bfca;
        }

        :global(.ant-pagination-item-active) {
            background-color: #65c5c2;
            border-radius: 50%;
            border-color: #65c5c2;
        }

        :global(.ant-pagination-item-active a) {
            color: #35383f;
        }

        :global(.ant-select) {
            :global(.ant-select-selector) {
                background-color: #1f222a;
                border: 1px solid #1f222a;
                color: #ffffff;
            }
        }
    }
}

:global(.ant-select-dropdown) {
    background: #1f222a;
    border: 1px solid #6e7f95;

    :global(.ant-select-item) {
        color: #6e7f95;
        border-radius: 0px;

        &:not(:last-child) {
            border-bottom: 1px solid #6e7f95;
        }

        &:hover {
            color: #fff;
        }
    }
}

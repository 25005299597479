.mainWrapper {
    .contentTopBar {
        display: flex;
        justify-content: space-between;
        gap: 20px;
        @media (max-width: 1200px) {
            flex-direction: column;
            justify-content: flex-start;
        }
        .uniDataContainer {
            flex-grow: 1;
            h1 {
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
                margin: 0px;
                color: #fff;
                margin-bottom: 4px;
                @media (min-width: 1200px) {
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    max-width: 12em;
                }
            }

            h2 {
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 15px;
                margin: 0px;
                color: #59b4d1;
                cursor: pointer;
            }
        }
        .filterside {
            display: flex;
            justify-content: flex-end;
            gap: 20px;
            @media (max-width: 1200px) {
                justify-content: flex-start;
            }
            @media (max-width: 690px) {
                flex-wrap: wrap;
            }
            .groupByContainer {
                display: flex;
                gap: 8px;
                align-items: center;

                h1 {
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 18px;
                    color: #55585a;
                    margin: 0px;
                }

                .selectorStyle {
                    :global(.ant-select-selector) {
                        background-color: #1f222a !important;
                        border: 1px solid #1f222a;
                        color: #ffffff;
                        min-height: 40px;
                        display: flex;
                        align-items: center;
                        border-radius: 12px;
                        width: 200px;

                        :global(.ant-select-selection-placeholder) {
                            color: #898f96 !important;
                        }

                        :global(.ant-select-selection-item) {
                            color: #898f96;
                        }
                    }

                    :global(.ant-select-arrow) {
                        color: #898f96;
                        font-size: 10px;
                    }
                }
            }
            .inputBtnContainer {
                display: flex;
                align-items: center;
                gap: 8px;
                justify-content: flex-end;
                @media (max-width: 690px) {
                    flex-wrap: wrap;
                    justify-content: flex-start;
                }
                .searchInput {
                    width: 200px;
                    border-radius: 24px;
                    background-color: #262a35;
                    padding: 10px 12px 9px 12px;
                    border: 1px solid #262a35;
                    color: #4a5f7a;

                    :global(.ant-input) {
                        background-color: #262a35;
                        color: #4a5f7a;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 21px;

                        &::placeholder {
                            color: #4a5f7a;
                        }
                    }
                }

                .settingIconStyle {
                    border-radius: 12px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: 1px solid #4a5f7a;
                    height: 40px;
                    width: 40px;
                    cursor: pointer;

                    .settingIcon {
                        height: 16px;
                        width: 16px;
                    }
                }

                .addBtnStyle {
                    padding: 10px 16px 9px 16px;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 16px;
                }
            }
        }
    }

    .contentCardContainer {
        margin-top: 49px;
        display: flex;
        overflow-x: auto;

        .contentCardInnerContainer {
            padding: 0px 5px;
            min-width: 24%;
            margin-bottom: 12px;

            @media (max-width: 1375px) {
                min-width: 50%;
            }

            @media (max-width: 828px) {
                min-width: 100%;
            }

            .allCardContainer {
                border-radius: 12px;
                background-color: #1f2732;
                padding: 16px;

                .cardTitleContainer {
                    display: flex;
                    align-items: center;
                    margin-bottom: 16px;

                    .cardTitleStyle {
                        display: flex;
                        align-items: center;
                        width: 60%;
                        gap: 5px;

                        h1 {
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 18px;
                            color: #55585a;
                            margin: 0px;
                        }

                        .itemsNo {
                            border-radius: 50px;
                            height: 20px;
                            width: 20px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            background-color: #121617;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 18px;
                            color: #55585a;
                        }
                    }

                    .moreIconContainer {
                        display: flex;
                        justify-content: end;
                        align-items: center;
                        width: 40%;

                        img {
                            cursor: pointer;
                        }
                    }
                }

                .cardsContainer {
                    height: 70vh;
                    overflow-y: auto;
                    padding-right: 10px;

                    .skeletonContainer {
                        padding: 16px;
                        border-radius: 12px;
                        background-color: #121617;
                        margin-bottom: 12px;

                        .lables {
                            display: flex;
                            padding: 8px 0px;
                            gap: 5px;
                        }

                        .description {
                            padding: 2px 0px;
                        }

                        .usersContainer {
                            display: flex;
                            align-items: center;

                            .commits {
                                display: flex;
                                gap: 5px;
                                width: 50%;
                            }

                            .user {
                                display: flex;
                                width: 50%;
                                justify-content: right;
                            }
                        }
                    }

                    .cardItemsContainer {
                        padding: 16px;
                        border-radius: 12px;
                        background-color: #121617;
                        margin-bottom: 12px;
                        cursor: pointer;

                        .cardItemContainer {
                            display: flex;
                            align-items: center;

                            .cardTitleStyle {
                                display: flex;
                                align-items: center;
                                width: 60%;
                                gap: 5px;

                                h1 {
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 600;
                                    line-height: 140%;
                                    color: #fff;
                                    margin: 0px;
                                }
                            }

                            .moreIconContainer {
                                display: flex;
                                justify-content: end;
                                align-items: center;
                                width: 40%;

                                img {
                                    cursor: pointer;
                                }
                            }
                        }

                        .labelDateContainer {
                            display: flex;
                            gap: 5px;
                            padding-top: 12px;
                            flex-wrap: wrap;

                            .labelStyle {
                                border-radius: 14px;
                                font-size: 12px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: 18px;
                                padding: 0px 8px;
                                color: #55585a;
                                background-color: #262a35;
                            }
                        }

                        .imgContainer {
                            padding: 16px 0px;

                            .imgStyle {
                                border-radius: 12px;
                                width: 100%;
                                height: 120px;
                                object-fit: cover;
                            }
                        }

                        .descriptionStyle {
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 18px;
                            color: #55585a !important;

                            span {
                                background-color: unset !important;
                                color: #55585a !important;
                            }
                        }

                        .repostMesgContainer {
                            display: flex;
                            align-items: center;
                            gap: 16px;

                            .repostMesgStyle {
                                display: flex;
                                align-items: center;
                                gap: 3px;

                                h1 {
                                    font-size: 12px;
                                    font-style: normal;
                                    font-weight: 600;
                                    line-height: 18px;
                                    margin: 0px;
                                    color: #1f2732;
                                }
                            }
                        }

                        .assigneeStyle {
                            display: flex;
                            margin-bottom: 16px;
                            margin-left: 12px;
                            justify-content: center;

                            .assigneeItemStyle {
                                height: 32px;
                                width: 32px;
                                border-radius: 50%;
                                overflow: hidden;
                                background-color: #2e3133;
                                border: 3px solid #2e3133;
                                margin-left: -10px;
                                color: #f75555;
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                .imgStyle {
                                    height: 100%;
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }

            .cardFooter {
                margin-top: 10px;

                .btnStyle {
                    padding: 10px 16px 9px 16px;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 21px;
                    color: #898f96;
                    border: 1px dashed #898f96;
                    height: max-content;
                    background-color: transparent;
                }
            }
        }
    }
}

.dec {
    margin-top: 10px;
    color: #55585a;
    font-size: 12px;
    font-family: "Archivo";
}

.selectItemstyle {
    background: #1f222a;
    border: 1px solid #6e7f95;

    :global(.ant-select-item) {
        color: #6e7f95;
        border-radius: 0px;

        &:not(:last-child) {
            border-bottom: 1px solid #6e7f95;
        }

        &:hover {
            color: #fff;
        }
    }
}

:global(.ant-btn) {
    &:global(.ant-btn-default) {
        &.darkbtn {
            padding: 12px 24px;
            border: 1px solid #65c5c2;
            border-radius: 12px;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            color: #fff;
            background: #65c5c2;
            height: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            &:hover {
                text-decoration: none;
                color: #fff;
                border: 1px solid #65c5c2;
            }
        }
        &.lightbtn {
            padding: 12px 24px;
            border: 1px solid #898f96;
            border-radius: 12px;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            color: #898f96;
            background: transparent;
            height: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            &:hover {
                text-decoration: none;
                border: 1px solid #898f96;
                color: #898f96;
            }
        }
    }
}
.commonInput {
    background-color: #1F222A;
    border-radius: 12px;
    padding: 12px;
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    &:focus,
    &:hover {
        border-color: #898f96;
        box-shadow: unset;
    }
    &::placeholder {
        color: #fff;
        font-size: 12px;
    }
}

.errorMsg {
    color: red;
    margin: 10px;
}

.ql-container {
  font-size: 16px;
}

/* Set content for font-families */
.ql-font-comicSans,
.ql-font span[data-value="comicSans"]::before {
  font-family: 'Comic Sans MS', sans-serif;
}
.ql-font-courierNew,
.ql-font span[data-value="courierNew"]::before {
  font-family: "Courier New";
}
.ql-font-georgia,
.ql-font span[data-value="georgia"]::before {
  font-family: 'Georgia', serif;
}
.ql-font-helvetica,
.ql-font span[data-value="helvetica"]::before {
  font-family: 'Helvetica', sans-serif;
}
.ql-font-lucida,
.ql-font span[data-value="Lucida"]::before {
  font-family: 'Lucida', sans-serif;
}
.ql-font-arial,
.ql-font span[data-label='Arial']::before {
  font-family: 'Arial';
}
.ql-font-Calibri,
.ql-font span[data-value="Calibri"]::before {
  font-family: 'Calibri';
}
.ql-font-TimesNewRoman,
.ql-font span[data-value="TimesNewRoman"]::before {
  font-family: 'TimesNewRoman', sans-serif;

}
.ql-font-roboto,
.ql-font span[data-label='Roboto']::before {
  font-family: 'Roboto';
}
.ql-font-raleway,
.ql-font span[data-label='Raleway']::before {
  font-family: 'Raleway';
}
.ql-font-montserrat,
.ql-font span[data-label='Montserrat']::before {
  font-family: 'Montserrat';
}
.ql-font-lato,
.ql-font span[data-label='Lato']::before {
  font-family: 'Lato';
}
.ql-font-rubik,
.ql-font span[data-label='Rubik']::before {
  font-family: 'Rubik';
}

/* Set content for sizes */
.ql-size-extra-small,
.ql-size span[data-value="extra-small"]::before {
  font-size: 13px !important;
}
.ql-size-small,
.ql-size span[data-value="small"]::before {
  font-size: 14px !important;
}
.ql-size-medium,
.ql-size span[data-value="medium"]::before {
  font-size: 18px !important;
}
.ql-size-large,
.ql-size span[data-value="large"]::before {
  font-size: 20px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='9px']::before {
  content: '9';
  font-size: 9px !important;
}

.ql-snow .ql-size .ql-picker-item[data-value='10px']::before {
  content: '10';
  font-size: 10px !important;
}

.ql-snow .ql-size .ql-picker-item[data-value='11px']::before {
  content: '11';
  font-size: 11px !important;
}
.ql-snow .ql-size .ql-picker-item[data-value='12px']::before {
  content: '12';
  font-size: 12px !important;
}

.ql-snow .ql-size .ql-picker-item[data-value='14px']::before {
  content: '14';
  font-size: 14px !important;
}

.ql-snow .ql-size .ql-picker-item[data-value='16px']::before {
  content: '16';
  font-size: 16px !important;
}
.ql-snow .ql-size .ql-picker-item[data-value='18px']::before {
  content: '18';
  font-size: 18px !important;
}

.ql-snow .ql-size .ql-picker-item[data-value='20px']::before {
  content: '20';
  font-size: 20px !important;
}

.ql-snow .ql-size .ql-picker-item[data-value='22px']::before {
  content: '22';
  font-size: 22px !important;
}
.ql-snow .ql-size .ql-picker-item[data-value='24px']::before {
  content: '24';
  font-size: 24px !important;
}

.ql-snow .ql-size .ql-picker-item[data-value='26px']::before {
  content: '26';
  font-size: 26px !important;
}

.ql-snow .ql-size .ql-picker-item[data-value='28px']::before {
  content: '28';
  font-size: 28px !important;
}

.ql-size-9px {
  font-size: 9px;
}
.ql-size-10px {
  font-size: 10px;
}
.ql-size-11px {
  font-size: 11px;
}
.ql-size-12px {
  font-size: 12px;
}
.ql-size-14px {
  font-size: 14px;
}
.ql-size-16px {
  font-size: 16px;
}
.ql-size-18px {
  font-size: 18px;
}
.ql-size-20px {
  font-size: 20px;
}
.ql-size-22px {
  font-size: 22px;
}
.ql-size-24px {
  font-size: 24px;
}
.ql-size-26px {
  font-size: 26px;
}
.ql-size-28px {
  font-size: 28px;
}
.ql-snow .ql-picker.ql-font {
  width: 150px;
}
.ql-snow .ql-picker.ql-size {
  width: 60px;
}
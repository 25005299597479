.mainContainer {
    background-color: #1f2732;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    /* align-items: center; */

    .innerContainer {
        width: 56%;
        margin-top: 80px;

        .logoStyle {
            width: 100%;
            max-width: 428px;
            height: 126px;
            margin-bottom: 50px;
        }

        .center {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .paragraph {
            margin: 10px 80px;
            text-align: center;
        }

        h1 {
            color: #6e7f95;
            text-align: center;
            font-size: 48px;
            font-style: normal;
            font-weight: 700;
            margin-top: 0;
            line-height: 160%;
            margin-top: 50px;
        }

        li {
            color: #6e7f95;
            margin-bottom: 10px;
            font-size: 16px;
            font-weight: 500;
        }

        :global(li::marker) {
            color: #ffffff;
        }

        p {
            color: #6e7f95;
            font-size: 24px;
            text-align: left;
            font-style: normal;
            font-weight: 400;
            line-height: 160%;
            letter-spacing: 0.2px;
            margin-bottom: 12px;
        }

        h4 {
            color: #ffffff;
            font-size: 24px;
            text-align: left;
            font-style: normal;
            font-weight: 700;
            line-height: 160%;
            letter-spacing: 0.2px;
            margin-top: 30px;
            margin-bottom: 0;
        }

        h5 {
            color: #6e7f95;
            font-size: 24px;
            text-align: left;
            font-style: normal;
            font-weight: 400;
            line-height: 160%;
            letter-spacing: 0.2px;
            margin-top: 10px;
            margin-bottom: 0;
        }
    }

    @media (max-width: 768px) {
        .logoStyle {
            width: 100%;
            max-width: 300px !important;
            height: 100px !important;
            margin-bottom: 20px;
        }
        /* Adjust font sizes for smaller screens */
        .innerContainer {
            width: 80%;
            margin-top: 40px;

            h1 {
                font-size: 36px;
            }

            li {
                font-size: 14px;
            }

            p {
                font-size: 14px;
            }

            h4,
            h5 {
                font-size: 14px;
            }
        }
    }
}

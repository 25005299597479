.mainContainer {
    background-color: #1f2732;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .innerContainer {
        width: 539px;
        margin: auto;

        .logoStyle {
            width: 100%;
            max-width: 428px;
            height: 126px;
            margin-bottom: 50px;
        }

        h1 {
            color: #fff;
            text-align: center;

            font-size: 48px;
            font-style: normal;
            font-weight: 700;
            margin-top: 0px;
            line-height: 160%;
            margin-top: 50px;
        }

        p {
            color: #fafafa;
            font-size: 24px;
            text-align: left;
            font-style: normal;
            font-weight: 400;
            line-height: 160%;
            letter-spacing: 0.2px;
            margin-bottom: 12px;
        }

        .inputStyle {
            display: flex;
            height: 75px;
            width: 100%;
            padding: 18px 20px;
            align-items: center;
            gap: 12px;
            border-radius: 16px;
            background: #141c27;
            color: #9e9e9e;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            border: 1px solid #dbdfe4;
            line-height: 160%;
            letter-spacing: 0.2px;
            &::placeholder {
                color: #9e9e9e;
            }

            &:hover {
                border: 1px solid #dbdfe4;
            }
        }

        .btnContainer {
            padding: 0px 20px;

            .signinStyle {
                padding: 18px 16px;
                text-align: center;
                align-items: center;
                gap: 10px;
                border-radius: 16px;
                background: #65c5c2;
                color: #fff;
                text-align: center;
                font-family: Archivo;
                font-size: 24px;
                font-style: normal;
                font-weight: 700;
                width: 100%;
                height: 74px;
                line-height: 160%;
                letter-spacing: 0.2px;
                margin-top: 36px;

                &:hover {
                    color: #fff !important;
                    border: none;
                }
            }

            .cancelStyle {
                display: flex;
                padding: 18px 16px;
                justify-content: center;
                align-items: center;
                gap: 10px;
                border-radius: 16px;
                border: 1px solid #dbdfe4;
                background-color: transparent;
                color: #dbdfe4;
                text-align: center;
                width: 100%;
                height: 74px;
                font-size: 24px;
                font-style: normal;
                font-weight: 700;
                line-height: 160%;
                letter-spacing: 0.2px;
                margin-top: 56px;

                &:hover {
                    color: #fff !important;
                    border: 1px solid #dbdfe4;
                }
            }
        }
    }
}

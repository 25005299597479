.sidebar {
    width: 270px !important;
    min-width: 270px !important;
    border-right: unset !important;
    @media (max-width: 991px) {
        width: auto !important;
        min-width: auto !important;
    }
    .logoHeader {
        position: static;
        top: 0;
        z-index: 99;
        width: 100%;
        padding: 20px 20px;
        .logoWrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            img {
                padding-bottom: 4px;
            }
            .menuIcon {
                width: 20px;
                height: 20px;
                display: none;
                @media (max-width: 991px) {
                    display: block;
                }
            }
        }
    }
    :global(.ps-sidebar-container) {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        height: 100%;
        max-height: 100%;
        overflow: hidden;
        background-color: #1f222a;
        width: 100%;
        border-right: 1px solid #2e3133;
        .topMenu {
            width: 100%;
            :global(.ps-menu-root) {
                max-height: calc(100vh - 215px);
                overflow: hidden auto;
                padding: 10px 8px 0 8px;
                ul {
                    li.menuItem {
                        min-height: 60px;
                        img {
                            width: 22px;
                            height: 22px;
                        }
                        a {
                            text-decoration: none;
                            &:global(.ps-menu-button) {
                                padding: 0;
                                color: #5c6f87;
                                &:hover,
                                &:focus {
                                    background-color: transparent;
                                    color: #65c5c2;
                                }
                            }
                            &.iconText {
                                display: flex;
                                justify-content: space-between;
                                padding: 16px 16px;
                                color: #5c6f87;
                                border-bottom: 1px solid transparent;
                                .menuiconActive {
                                    display: none;
                                }
                                .menuLinkItem {
                                    display: flex;
                                    gap: 24px;
                                    align-items: center;
                                    .text {
                                        font-size: 14px;
                                        font-style: normal;
                                        font-weight: 500;
                                        line-height: 21px;
                                        color: #5c6f87;
                                    }
                                }
                                &:hover,
                                &:focus {
                                    cursor: pointer;
                                    border-radius: 12px;
                                    background: #1f2732;
                                    box-shadow: 0px 2px 0px 0px rgba(100, 197, 193, 0.2);
                                    border-bottom: 1px solid rgba(101, 197, 194, 0.2);
                                    color: #65c5c2;
                                    .text {
                                        color: #65c5c2;
                                    }
                                    img {
                                        filter: drop-shadow(0px 0px 0px #65c5c2);
                                    }
                                }
                                &.active {
                                    .menuIcon {
                                        display: none;
                                    }
                                    .menuiconActive {
                                        display: block;
                                    }
                                    cursor: pointer;
                                    border-radius: 12px;
                                    background: #1f2732;
                                    box-shadow: 0px 2px 0px 0px rgba(100, 197, 193, 0.2);
                                    border-bottom: 1px solid rgba(101, 197, 194, 0.2);
                                    color: #65c5c2;
                                    .text {
                                        color: #65c5c2;
                                    }
                                    img {
                                        filter: drop-shadow(0px 0px 0px #65c5c2);
                                    }
                                }
                            }
                        }
                    }
                    p {
                        &.adminAuth {
                            font-weight: 700;
                            font-size: 18px;
                            color: #55585a;
                            padding: 14px;
                            margin: 0;
                        }
                    }
                }
            }
        }

        .BottomMenu {
            padding: 0 8px;

            .iconText {
                padding: 16px 16px;
                display: flex;
                align-items: center;
                gap: 24px;
                img {
                    width: 20px;
                    height: 20px;
                }
                p {
                    margin: 0;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 21px;
                    color: #5c6f87;
                }
                &:hover,
                &:focus {
                    cursor: pointer;
                    border-radius: 12px;
                    background: #1f2732;
                    box-shadow: 0px 2px 0px 0px rgba(100, 197, 193, 0.2);
                    p {
                        color: #65c5c2;
                    }
                    img {
                        filter: drop-shadow(0px 0px 0px #65c5c2);
                    }
                }
            }
            .coppyrights {
                padding: 24px;
                p {
                    font-size: 10px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 15px; /* 150% */
                    text-transform: uppercase;
                    color: #fff;
                    margin: 0;
                    span {
                        font-size: 10px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 22px;
                        color: #898f96;
                        text-transform: capitalize;
                    }
                }
            }
        }
    }
}

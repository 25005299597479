:global(.ant-drawer-content) {
    &.contentDrawer {
        background-color: #1f2732;

        :global(.ant-drawer-header) {
            border-bottom: 1px solid #2e3133;
            padding: 8px 30px;

            :global(.ant-drawer-header-title) {
                :global(.ant-drawer-close) {
                    display: none;
                }

                :global(.ant-drawer-title) {
                    .topTitleDrawer {
                        display: flex;
                        justify-content: space-between;
                        width: 100%;
                        align-items: center;

                        h4 {
                            color: #fff;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 21px;
                            margin: 0;
                            display: flex;
                            align-items: center;

                            span:global(.anticon) {
                                color: #55585a;
                                margin: 0 8px;
                            }

                            span.activelink {
                                color: #ffffff;
                            }
                        }

                        .right {
                            display: flex;
                            align-items: center;
                            gap: 20px;

                            span:global(.anticon) {
                                color: #55585a;
                                padding: 4px;
                                width: 24px;
                                height: 24px;
                                display: flex;
                                align-items: center;

                                svg {
                                    width: 24px;
                                    height: 24px;
                                }
                            }
                        }
                    }
                }
            }
        }

        :global(.ant-drawer-body) {
            padding: 20px 30px;
            margin: 8px 8px 8px 0px;

            .inputStyle {
                border: 1px solid #2e3133;
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
                line-height: 30px;

                &::placeholder {
                    color: #55585a;
                    font-size: 12px;
                    text-align: right;
                }
            }

            .urlValidation {
                border: 0px;

                &::placeholder {
                    color: #6e7f95;
                }
            }

            .lableLight {
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                line-height: 18px;
                color: #4a5f7a;
                // margin: 24px 0 5px 0;

                &.white {
                    color: #fff;
                    font-size: 14px;
                    margin-bottom: 8px;
                }
            }

            .avatorList {
                display: flex;
                align-items: center;
                gap: 5px;
                margin: 10px 0px;
                flex-wrap: wrap;

                .avator {
                    min-width: 40px;
                    width: 40px;
                    min-height: 40px;
                    height: 40px;
                    border-radius: 50%;
                    object-fit: contain;
                    border: 1px solid #1f222a;
                    display: flex;
                    align-items: center;
                    margin-right: 15px;
                    justify-content: center;

                    .addIcon {
                        svg {
                            color: #4a5f7a;
                            fill: #4a5f7a;
                            width: 16px;
                            height: 16px;
                        }
                    }
                }

                .memberStyling {
                    background-color: #1e414b;
                    border: 1px solid #1e414b;
                    color: #f75555;
                    padding: 2px 8px;
                    border-radius: 14px;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    margin: 0px;
                    text-align: center;
                    color: #7dc066;

                    span {
                        margin-left: 8px;
                        cursor: pointer;

                        svg {
                            color: white;
                        }
                    }
                }
            }

            .addLabels {
                display: flex;
                align-items: center;
                gap: 5px;

                .addIcon {
                    border-radius: 16px;
                    border: 1px solid #35383f;
                    background: #1f222a;
                    padding: 14px;

                    svg {
                        color: #fff;
                        fill: #fff;
                        width: 20px;
                        height: 20px;
                    }
                }

                .featurebtn {
                    color: #65c5c2;
                    background: rgba(100, 198, 193, 0.08);
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 19px;
                    padding: 13px;
                    border: 1px solid rgba(100, 198, 193, 0.08);
                }
            }

            .notetextarea {
                border: 1px solid #1f222a;
                background: transparent;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 30px;
                min-height: 87px;
                background-color: #1f222a;
                color: #fff;

                &::placeholder {
                    color: #6e7f95;
                    font-size: 14px;
                    font-weight: 400;
                }

                &:focus {
                    box-shadow: unset;
                }
            }

            .checkBoxContainer {
                margin-bottom: 17px;
                margin-top: 30px;

                :global(.ant-checkbox-group) {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    margin-top: 20px;

                    :global(.ant-checkbox-wrapper) {
                        span {
                            color: #55585a;
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 21px;
                        }

                        &:global(.ant-checkbox-wrapper-checked) {
                            span {
                                color: #ffffff;
                                font-size: 14px;
                                font-weight: 400;
                                line-height: 21px;
                            }
                        }

                        :global(.ant-checkbox) {
                            :global(.ant-checkbox-inner) {
                                height: 24px;
                                width: 24px;
                                border: 3px solid #65c5c2;
                                border-radius: 7px;
                                background-color: transparent;
                            }

                            &:global(.ant-checkbox-checked) {
                                :global(.ant-checkbox-inner) {
                                    height: 24px;
                                    width: 24px;
                                    border: 3px solid #65c5c2;
                                    background-color: #65c5c2;
                                }

                                &::after {
                                    border-color: #65c5c2;
                                    border-radius: 7px;
                                }
                            }
                        }
                    }
                }

                .addContentBtn {
                    margin-top: 24px;
                    background: rgba(100, 198, 193, 0.08);
                    color: #65c5c2;
                    padding: 10px 16px 9px 16px;
                    border-radius: 12px;
                    border: 1px solid rgba(100, 198, 193, 0.08);

                    .addIcon {
                        width: 16px;
                        height: 16px;
                        background: #65c5c2;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        svg {
                            color: #fff;
                            fill: #fff;
                            width: 10px;
                            height: 10px;
                        }
                    }
                }
            }

            .uploadFiles {
                margin-top: 10px;

                :global(.ant-upload) {
                    &:global(.ant-upload-drag) {
                        border-radius: 12px;
                        border: 1px dashed #4a5f7a;
                        background: #1f222a;
                        max-height: 130px;
                    }
                }

                h3 {
                    color: #4a5f7a;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 27px;
                    margin-bottom: 10px;
                    margin-top: 0;
                }

                p {
                    color: #4a5f7a;
                    font-size: 10px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 15px;
                    text-transform: uppercase;

                    span {
                        color: #65c5c2;
                        margin-left: 10px;
                    }
                }
            }

            .socialBtnList {
                display: flex;
                align-items: center;
                gap: 20px;
                margin: 20px 0;

                :global(.ant-btn) {
                    border-radius: 6px;

                    &.offBtn {
                        background: rgba(100, 198, 193, 0.08);
                        color: #65c5c2;

                        border: 1px solid rgba(100, 198, 193, 0.08);
                    }
                }
            }
        }

        :global(.ant-drawer-footer) {
            padding: 10px 30px 30px 30px;

            .btnblock {
                display: flex;
                justify-content: end;
                align-items: center;
                gap: 10px;
            }
        }

        :global(.ant-select) {
            &.drawerselect {
                width: 100% !important;
                min-height: 40px;

                :global(.ant-select-selector) {
                    background-color: #1f222a !important;
                    border: 1px solid #1f222a;
                    color: #ffffff;
                    min-height: 40px;
                    display: flex;
                    align-items: center;
                    border-radius: 12px;

                    input {
                        color: #fff;
                        height: 100%;
                    }

                    :global(.ant-select-selection-placeholder) {
                        color: #fff;
                    }
                }

                &:global(.ant-select-focused) {
                    :global(.ant-select-selector) {
                        box-shadow: unset !important;
                        border-color: #4a5f7a !important;
                    }
                }

                &:hover {
                    :global(.ant-select-selector) {
                        box-shadow: unset !important;
                        border-color: #4a5f7a !important;
                    }
                }
            }
        }

        :global(.ant-select) {
            &.drawerMultiSelect {
                width: 100% !important;
                min-height: 40px;

                :global(.ant-select-selector) {
                    background-color: #1f222a !important;
                    border: 1px solid #1f222a;
                    color: #1f222a;
                    min-height: 40px;
                    display: flex;
                    align-items: center;
                    border-radius: 12px;

                    input {
                        color: #fff;
                        height: 100%;
                    }

                    :global(.ant-select-selection-placeholder) {
                        color: #fff;
                    }
                }

                &:global(.ant-select-focused) {
                    :global(.ant-select-selector) {
                        box-shadow: unset !important;
                        border-color: #4a5f7a !important;
                    }
                }

                &:hover {
                    :global(.ant-select-selector) {
                        box-shadow: unset !important;
                        border-color: #4a5f7a !important;
                    }
                }
            }
        }

        .uploadPicture {
            margin-top: 10px;

            :global(.ant-upload) {
                margin-top: 20px;
                margin-bottom: 20px;

                :global(.ant-btn-default) {
                    padding: 12px 24px;
                    border: 1px solid #65c5c2;
                    border-radius: 12px;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 24px;
                    color: #fff;
                    background: #65c5c2;
                    height: auto;
                }

                span {
                    color: white;
                }
            }
        }
        .uploadVideo {
            margin-top: 10px;

            :global(.ant-upload) {
                margin-top: 20px;
                margin-bottom: 20px;

                :global(.ant-btn-default) {
                    padding: 12px 24px;
                    border: 1px solid rgba(100, 198, 193, 0.08);
                    border-radius: 12px;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 24px;
                    color: #65c5c2;
                    background: rgba(100, 198, 193, 0.08);
                    height: auto;
                }

                span {
                    color: #65c5c2;
                }
            }
        }
    }
}

:global(.ant-picker) {
    &.dateSelectStyle {
        background-color: #1f222a;
        border: 1px solid #1f222a;
        color: #ffffff;
        min-height: 40px;
        display: flex;
        align-items: center;
        border-radius: 12px;

        :global(.ant-picker-input > input) {
            color: #ffffff;

            &::placeholder {
                color: #ffffff;
            }
        }

        :global(.ant-picker-input) {
            :global(.ant-picker-suffix) {
                color: #ffffff;
            }
        }
    }
}

:global(.ant-picker-dropdown) {
    :global(.ant-picker-header-view) {
        color: #ffffff;
    }

    :global(.ant-picker-header > button) {
        color: #ffffff;
    }

    :global(.ant-picker-date-panel .ant-picker-content th) {
        color: #ffffff;
    }

    :global(.ant-picker-content td) {
        color: #ffffff;
    }

    :global(.ant-picker-content .ant-picker-cell-disabled::before) {
        background-color: #1c1e22;
    }

    :global(.ant-picker-today-btn) {
        color: #ffffff;
        display: none;

        &:hover {
            color: #ffffff;
        }
    }

    :global(.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before) {
        border: 1px solid #ffffff;
    }

    :global(.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner) {
        background-color: #ffffff;
        color: #121617;
    }

    :global(.ant-picker-panel-container) {
        :global(.ant-picker-panel) {
            background-color: #1f2732;
        }
    }

    :global(.ant-picker-time-panel-column > li.ant-picker-time-panel-cell) {
        :global(.ant-picker-time-panel-cell-inner) {
            color: white;
        }
    }

    :global(.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected) {
        :global(.ant-picker-time-panel-cell-inner) {
            color: #1f2732;
        }
    }

    :global(.ant-picker-ranges > li) {
        a {
            color: white;
        }

        :global(.ant-btn) {
            color: white;
            background-color: #1f222a;
            box-shadow: none;

            &:hover {
                background-color: #1f222a;
            }
        }
    }
}

.dropdownstyle {
    background: #1f222a;
    border: 1px solid #6e7f95;

    :global(.ant-select-item) {
        color: #6e7f95;
        border-radius: 0px;

        &:not(:last-child) {
            border-bottom: 1px solid #6e7f95;
        }

        &:hover {
            color: #fff;
        }
    }
}

.assigneeItemStyle {
    // height: 40px;
    // width: 100px;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
    // border-radius: 50%;
    // overflow: hidden;
    background-color: rgba(100, 198, 193, 0.08);

    border: 3px solid #2e3133;
    color: #f75555;
    display: flex;
    justify-content: center;
    align-items: center;

    // .imgStyle {
    //     height: 100%;
    //     width: 100%;
    // }
}

:global(.ql-toolbar.ql-snow) {
    border: unset !important;
    background-color: #1f222a;
    border-radius: 12px 12px 0px 0px !important;
}

:global(.ql-editor) {
    height: 160px !important;
    color: #6e7f95 !important;
    background-color: transparent !important;

    span {
        color: #6e7f95 !important;
        background-color: transparent !important;
    }

    // border: unset;
}

:global(.ql-container.ql-snow) {
    // height: 200px;
    border: unset !important;
    background-color: #1f222a;
    border-radius: 0px 0px 12px 12px !important;
}

:global(.ql-blank) {
    &:placeholder {
        font-size: 220px !important;
    }
}

:global(.ql-editor.ql-blank::before) {
    color: #6e7f95 !important;
    font-size: 14px;
    font-weight: 400;
    font-style: normal !important;
}

:global(.ant-upload-list-item) {
    :global(.ant-upload-icon) {
        span {
            color: green !important;
        }
    }

    :global(.ant-upload-list-item-name) {
        color: green !important;
    }

    :global(.anticon-delete) {
        color: red !important;
    }
}

:global(.ant-select) {
    &:global(.ant-select-open) {
        :global(.ant-select-selection-item) {
            color: #fff;
        }
    }
    :global(.ant-select-clear) {
        background: transparent;
        span:global(.anticon) {
            color: #55585a;
            background-color: #1f222a;
            svg {
                width: 14px;
                height: 14px;
            }
        }
    }
}
:global(.ant-select-dropdown) {
    :global(.ant-select-item-option-selected) {
        background-color: transparent !important;
        color: #fff !important;
    }
}

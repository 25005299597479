.chatMainWrapper {
    display: flex;
    gap: 15px;

    .membersList {
        display: flex;
        align-items: center;
        flex-direction: column;
        background-color: #1c1c28;
        width: 100%;
        max-width: 190px;

        h3 {
            font-size: 20px;
            margin: 0;
            padding: 10px 0 5px 0;
            margin-bottom: 5px;
            color: #fff;
            border-bottom: 1px solid rgba(203, 203, 203, 0.4);
            width: 100%;
            text-align: center;
        }

        :global(.pn-member-list) {
            height: calc(100vh - 160px);
            overflow: auto;
            background-color: transparent;

            :global(.pn-member__presence) {
                width: 15px;
                height: 15px;
            }

            :global(.pn-member) {
                cursor: pointer;
                gap: 10px;
                align-items: center;

                :global(.pn-member__avatar) {
                    margin: 0;
                }
            }

            .membersStyle {
                padding: 6px 10px;
                cursor: pointer;
                display: flex;
                gap: 10px;
                align-items: center;
                margin-bottom: 5px;
                border: 1px solid transparent;
                border-radius: 5px;

                .profilePicStyle {
                    height: 40px;
                    width: 40px;
                    border-radius: 50%;
                }

                .profileStyle {
                    background-color: #9797a5;
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #1c1c28;
                    font-weight: 300;
                    font-family: "Archivo";
                }

                .nameStyle {
                    font-size: 18px;
                    color: #fff;
                    font-family: "Archivo";
                    font-weight: 300;
                }

                &:hover {
                    border: 1px solid rgba(255, 255, 255, 0.575);
                }
                .unreadCount {
                    color: #000;
                    background-color: #65c5c2;
                    border-radius: 50%;
                    font-size: 10px;
                    padding: 5px;
                    width: 22px;
                    height: 22px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                &.selectedMemberStyle {
                    background-color: #ffffff;

                    .profileStyle {
                        font-weight: 500;
                    }

                    .nameStyle {
                        color: #1c1c28;
                        font-weight: 500;
                    }
                }
            }

        }
    }

    .messageListWrapper {
        flex-grow: 1;
        background-color: #1c1c28;

        h3 {
            font-size: 20px;
            margin: 0;
            padding: 10px 5px 5px 10px;
            color: #fff;
            border-bottom: 1px solid rgba(203, 203, 203, 0.4);
            width: 100%;
            text-transform: capitalize;
            background: #1c1c28;
        }

        .messageList {
            min-height: calc(100vh - 208px);
            max-height: calc(100vh - 208px);
            overflow: auto;
            padding-top: 10px;

            :global(.pn-msg-list) {
                height: 100%;
                min-height: calc(100vh - 220px);

                display: flex;
                align-items: flex-end;

                // overflow: auto;
                :global(.pn-msg-list-scroller) {
                    // gap: 10px;
                }
            }

            :global(.pn-msg) {
                justify-content: flex-start;
                align-items: center;
                max-width: 85%;
                min-width: 120px;
                margin-right: auto;
                margin-left: 20px;
                padding: 0;

                &:hover {
                    background: transparent;
                }

                .messageWraper {
                    width: 100%;

                    .messageBox {
                        position: relative;

                        p {
                            margin: 0;
                            font-size: 14px;

                            &.messageTime {
                                font-size: 12px;
                                text-align: right;
                                color: rgba(228, 228, 235, 0.8);
                                padding-top: 7px;
                                padding-bottom: 5px;
                            }
                        }

                        p.messageText {
                            padding: 10px 15px;
                            background: #fff;
                            box-shadow: 0px 2px 0px 0px rgba(100, 197, 193, 0.2);
                            border-bottom: 1px solid rgba(101, 197, 194, 0.2);
                            border-radius: 10px;
                            color: #1f2732;

                            &::before {
                                content: "";
                                position: absolute;
                                width: 0;
                                height: 0;
                                border-top: 15px solid #fff;
                                border-left: 15px solid transparent;
                                border-right: 12px solid transparent;
                                top: 0;
                                left: -15px;
                            }
                        }
                    }
                }

                &:global(.pn-msg--own) {
                    justify-content: flex-end;
                    margin-left: auto;
                    margin-right: 20px;

                    .messageWraper {
                        .messageBox {
                            p.messageText {
                                color: #1c1c28;
                                background-color: #65c5c2;

                                &::before {
                                    display: none;
                                }

                                &::after {
                                    content: "";
                                    position: absolute;
                                    width: 0;
                                    height: 0;
                                    border-bottom: 12px solid #65c5c2;
                                    border-left: 16px solid transparent;
                                    border-right: 16px solid transparent;
                                    bottom: 26px;
                                    right: -16px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .messageInput {
            display: flex;
            gap: 15px;
            padding: 10px 9px;

            :global(.pn-msg-input) {
                :global(.pn-msg-input__send--active) {
                    color: #fff;

                    &:disabled {
                        color: #65c5c2;
                    }
                }
            }

            :global(.ant-input) {
                color: rgba(228, 228, 235, 0.8);
                background: #2a2a39;
                padding: 6px 14px 8px;
                font-size: 13px;
                border-radius: 20px;
                border: 0px solid;

                &:focus {
                    border: 0px solid;
                    box-shadow: unset;
                }

                &::placeholder {
                    color: rgba(228, 228, 235, 0.4);
                }
            }
        }
    }
}
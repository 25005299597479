.mainWrapper {
    h1 {
        color: #fff;
        font-weight: 600;
    }

    :global(.ant-tabs) {

        :global(.ant-tabs-nav) {
            &::before {
                border-bottom: 0px solid;
            }

            :global(.ant-tabs-tab) {
                font-family: "Archivo";
                font-weight: 400;
                font-size: 15px;
                color: #5c6f87;
                padding: 5px;

            }

            :global(.ant-tabs-tab-active) {
                :global(.ant-tabs-tab-btn) {
                    color: #65c5c2;
                }
            }

            :global(.ant-tabs-ink-bar) {
                background: #65c5c2;
            }

            :global(.ant-tabs-tab-btn) {
                &:active {
                    color: #65c5c2;
                }
            }
        }

    }

    .messageContainer {
        border: 1px solid #5c6f87;
        border-radius: 12px;
        background-color: #141c27;
        padding: 16px;
        margin: 8px 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .messageTitle {
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: 18px;
            color: #5c6f87;
            margin: 0px;

            span {
                font-weight: 500;
                color: #FFF;
            }
        }

        .replyTitle {
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px;
            color: #fff;
            margin: 0px;
            cursor: pointer;
        }

        .repliedTitle {
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px;
            color: #fff;
            margin: 0px;
            color: #8BC255;
        }

        .replyContainer {
            .inputStyle {
                border: 0px solid;
            }
        }
    }
}